@import "/css/uikit.scss";

ivp-post {

	.head {
		display: flex;
		justify-content: space-between;
		.user-data {
			display: flex;

			.thumbnail-container {
				width: 48px;
				height: 48px;
				border-radius: 48px;
				overflow: hidden;
				border: 1px solid $image-border-color;
				background-size: cover;
				margin: 5px;
				margin-right: 10px;
			}

			a {
				display: block;
				text-decoration: none;
				color:$text-color;
				margin: 5px;

				&.user-name {
					color: $text-color-strong;
					font-weight: bold;
				}
			}
		}

		.menu {
			display: flex;
			padding-top: 10px;

			span {
				color: $text-color-light;
				font-size: 13px;
			}

			img {
				margin-left: 10px;
				width: 20px;
				fill: currentColor;
				color: $text-color-strong;
			}

			.context-menu-icon {
				display: inline-block;
				margin-left: 10px;

				> div {
					display: flex;
					justify-content: space-between;
					width: 30px;
					padding: 6px;
					cursor: pointer;

					> div {
						width: 4px;
						height: 4px;
						background-color: $text-color;
						border-radius: 4px;
					}

					&:hover {
						background-color: rgba($active-element-color, 0.05);
						border-radius: 6px;
						> div {
							background-color: $active-element-color;
						}
					}
				}
			}
		}
	}

	.body {
		p {
			padding: 10px;
			margin: 0;
			font-size: 14px;
			line-height: 24px;
			font-family: Raleway, sans-serif;
			letter-spacing: 0.5px;
			width: 100%;
			display:block;
			text-align: justify;
		}

		.image-container {
			width: 100%;
			min-height: 600px;
			overflow: hidden;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $image-border-color;
			border-radius: 4px;

			img {
				position:absolute;
				transition: opacity 0.5s ease-in-out;
				opacity: 0;

				&.horz {
					height: 100%;
				}

				&.vert {
					width: 100%;
				}
			}

			.arrow {
				position: absolute;
				display: block;
				//top: 50%;
				z-index: 1;
				background-color: rgba(0,0,0,0.7);
				padding: 4px;
				opacity: 0.6;
				border: 1px solid rgba(white, 0.8);

				&:hover {
					opacity: 1;
				}

				.lnr {
					height: 32px;
					width: 32px;
					fill: currentColor;
					color: white;
				}
				&.left {
					left:0;
					border-left: none;
				}
				&.right {
					right: 0;
					border-right: none;
				}
			}
		}
	}

	.actions {
		display:flex;
		align-items: start;
		margin:0;
		position: relative;
		border-bottom: 1px solid $border-color-light;
		padding: 10px 0;

		a {
			text-decoration: none;
			color: $text-color;
			font-size: 14px;
			padding-right: 15px;
			display:flex;
			align-items: center;
			justify-content: center;


			&:last-child {
				padding-right: 0;
				right:0;
				position: absolute;
			}

			.lnr {
				width: 30px;
				height: 30px;
				padding: 3px;
				color: $text-color-ultra-light;
				fill: currentColor;
			}
		}
	}

	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ellipsis div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: rgba($active-element-color, 0.5);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}
	.lds-ellipsis div:nth-child(1) {
		left: 8px;
		animation: lds-ellipsis1 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(2) {
		left: 8px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(3) {
		left: 32px;
		animation: lds-ellipsis2 0.6s infinite;
	}
	.lds-ellipsis div:nth-child(4) {
		left: 56px;
		animation: lds-ellipsis3 0.6s infinite;
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}

}

