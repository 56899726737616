//
// Variables
// ----------------------

$gray: #333;
$gray-light: #aaa;
$gray-lighter: #eee;
$space: 40px;
$blue: #428bca;
$blue-dark: darken($blue, 5%);

// Mixin for transition/transform
@mixin translate($x, $y){
	-webkit-transform: translate($x, $y);
	-ms-transform: translate($x, $y); // IE9+
	transform: translate($x, $y);
}
@mixin transition($transition){
	-webkit-transition: $transition;
	transition: $transition;
}
@mixin transition-transform($transition){
	-webkit-transition: -webkit-transform $transition;
	-moz-transition: -moz-transform $transition;
	-o-transition: -o-transform $transition;
	transition: transform $transition;
}

//
// Body
// ----------------------

body{
	color: $gray;
	font-family: 'Helvetica', arial;
}

.wrap{
	padding: $space;
	text-align: center;
}

hr {
	clear: both;
	margin-top: $space;
	margin-bottom: $space;
	border: 0;
	border-top: 1px solid $gray-light;
}

h1{
	font-size: 30px;
	margin-bottom: $space;
}

p{
	margin-bottom: $space/2;
}

//
// Btn
// ----------------------

.btn{
	background: $blue;
	border: $blue-dark solid 1px;
	border-radius: 3px;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	padding: 8px 15px;
	text-decoration: none;
	text-align: center;
	min-width: 60px;
	position: relative;
	transition: color .1s ease;

	&:hover{
		background: $blue-dark;
	}

	&.btn-big{
		font-size: 18px;
		padding: 15px 20px;
		min-width: 100px;
	}

}

.btn-close{
	color: $gray-light;
	font-size: 30px;
	text-decoration: none;
	position: absolute; right: 10px; top: 0;

	&:hover{
		color: darken($gray-light, 10%);
	}

}

//
// Modal
// ----------------------

.modal{

	// This is modal bg
	&:before{
		content: "";
		display: none;
		background: rgba(0,0,0,.6);
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 10;
	}

	&:target{

		// Active animate in modal
		&:before{
			display: block;
		}
		.modal-dialog{
			@include translate(0, 0);
			top: 5%;
			//height: 90%;
			//max-height: 90%;
			//overflow-y: scroll;
		}

	}

	&.visible {

		// Active animate in modal
		&:before{
			display: block;
		}
		.modal-dialog{
			@include translate(0, 0);
			top: 5%;
			//height: 90%;
			//max-height: 90%;
			//overflow-y: scroll;
		}

	}

}

// Modal Dialog
// ----------------------

.modal-dialog{
	background: #fefefe;
	border: $gray solid 1px;
	border-radius: 5px;
	margin-left: -47.5%;
	position: absolute;
	left: 50%;
	top: -100%;
	z-index: 11;
	width: 95%;
	@include translate(0, -500%);
	@include transition-transform(#{"0.3s ease-out"});
}

@media screen and (min-width: 1024px) {
	.modal-dialog{
		margin-left: -30%;
		width: 60%;
	}

}

.modal-body{
	padding: $space/2;

}

.modal-header,
.modal-footer{
	padding: $space/4 $space/2;
}

.modal-header{
	border-bottom: $gray-lighter solid 1px;

	h2{
		font-size: 20px;
	}

}

.modal-footer{
	border-top: $gray-lighter solid 1px;
	text-align: right;

	.pure-button {
		min-width: 100px;
	}
}
